/* tslint:disable */
/* eslint-disable */
/**
 * EcoCerram API
 * EcoCerram API generated from BE
 *
 * The version of the OpenAPI document: 0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AlternativeSize } from './AlternativeSize';
import {
    AlternativeSizeFromJSON,
    AlternativeSizeFromJSONTyped,
    AlternativeSizeToJSON,
} from './AlternativeSize';

/**
 * 
 * @export
 * @interface Stock
 */
export interface Stock {
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    ean?: string;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    size?: StockSizeEnum;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    thickness?: StockThicknessEnum;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    priceForCurrentUser?: number;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    pricePerPackage?: number;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    sellingRateVAT?: number;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    unit?: StockUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    amountPerPackage?: number;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    amountPerPalette?: number;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    packagesOnStock?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Stock
     */
    atypicalTile?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    discontinuedDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    discontinuedEanCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    notDiscontinuedEanCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Stock
     */
    tileFaceImages?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Stock
     */
    showcaseImages?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Stock
     */
    technicalParameters?: Array<StockTechnicalParametersEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Stock
     */
    types?: Array<StockTypesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Stock
     */
    editions?: Array<StockEditionsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Stock
     */
    surfaces?: Array<StockSurfacesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Stock
     */
    designs?: Array<StockDesignsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Stock
     */
    colors?: Array<StockColorsEnum>;
    /**
     * 
     * @type {Array<AlternativeSize>}
     * @memberof Stock
     */
    alternativeSizes?: Array<AlternativeSize>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Stock
     */
    alternativeStockEanCodes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Stock
     */
    bathroomSeriesStockEanCodes?: Array<string>;
}


/**
 * @export
 */
export const StockSizeEnum = {
    _3060: 'DIMENSION_30_60',
    _6060: 'DIMENSION_60_60',
    _60120: 'DIMENSION_60_120',
    _3030: 'DIMENSION_30_30',
    _120120: 'DIMENSION_120_120',
    _8080: 'DIMENSION_80_80',
    _80160: 'DIMENSION_80_160',
    _100200: 'DIMENSION_100_200',
    _7530: 'DIMENSION_7_5_30',
    _5050: 'DIMENSION_50_50',
    _1590: 'DIMENSION_15_90',
    _4545: 'DIMENSION_45_45',
    _4040: 'DIMENSION_40_40',
    _408283: 'DIMENSION_40_8_28_3',
    _39898: 'DIMENSION_39_8_9_8',
    _2175: 'DIMENSION_21_7_5',
    _25575: 'DIMENSION_25_5_7_5',
    _492145: 'DIMENSION_49_2_14_5',
    _2030: 'DIMENSION_20_30',
    _30120: 'DIMENSION_30_120'
} as const;
export type StockSizeEnum = typeof StockSizeEnum[keyof typeof StockSizeEnum];

/**
 * @export
 */
export const StockThicknessEnum = {
    _7Mm: 'THICKNESS_7_MM',
    _8Mm: 'THICKNESS_8_MM',
    _2Cm: 'THICKNESS_2_CM',
    _75Mm: 'THICKNESS_7_5_MM',
    _9Mm: 'THICKNESS_9_MM',
    _10Mm: 'THICKNESS_10_MM',
    _5Mm: 'THICKNESS_5_MM',
    _85Mm: 'THICKNESS_8_5_MM',
    _35Mm: 'THICKNESS_35_MM',
    _30Mm: 'THICKNESS_30_MM',
    _55Mm: 'THICKNESS_5_5_MM'
} as const;
export type StockThicknessEnum = typeof StockThicknessEnum[keyof typeof StockThicknessEnum];

/**
 * @export
 */
export const StockUnitEnum = {
    Meter: 'METER',
    Piece: 'PIECE',
    MeterAsPiece: 'METER_AS_PIECE'
} as const;
export type StockUnitEnum = typeof StockUnitEnum[keyof typeof StockUnitEnum];

/**
 * @export
 */
export const StockTechnicalParametersEnum = {
    FrostResistant: 'FROST_RESISTANT',
    NonFrostResistant: 'NON_FROST_RESISTANT',
    AntiSlipR9: 'ANTI_SLIP_R9',
    AntiSlipR10: 'ANTI_SLIP_R10',
    AntiSlipR11: 'ANTI_SLIP_R11',
    ResistanceToSurfaceWearPei1: 'RESISTANCE_TO_SURFACE_WEAR_PEI_1',
    ResistanceToSurfaceWearPei2: 'RESISTANCE_TO_SURFACE_WEAR_PEI_2',
    ResistanceToSurfaceWearPei3: 'RESISTANCE_TO_SURFACE_WEAR_PEI_3',
    ResistanceToSurfaceWearPei4: 'RESISTANCE_TO_SURFACE_WEAR_PEI_4',
    Rectified: 'RECTIFIED'
} as const;
export type StockTechnicalParametersEnum = typeof StockTechnicalParametersEnum[keyof typeof StockTechnicalParametersEnum];

/**
 * @export
 */
export const StockTypesEnum = {
    BathroomSeries: 'BATHROOM_SERIES',
    FacadeCladding: 'FACADE_CLADDING',
    Tiling: 'TILING',
    Flooring: 'FLOORING',
    Decoration: 'DECORATION',
    MosaicHexagon: 'MOSAIC_HEXAGON'
} as const;
export type StockTypesEnum = typeof StockTypesEnum[keyof typeof StockTypesEnum];

/**
 * @export
 */
export const StockEditionsEnum = {
    New: 'NEW',
    Preparing: 'PREPARING',
    Discount: 'DISCOUNT',
    Discontinued: 'DISCONTINUED'
} as const;
export type StockEditionsEnum = typeof StockEditionsEnum[keyof typeof StockEditionsEnum];

/**
 * @export
 */
export const StockSurfacesEnum = {
    Matte: 'MATTE',
    Gloss: 'GLOSS',
    HighGloss: 'HIGH_GLOSS',
    Carving: 'CARVING',
    Texture: 'TEXTURE'
} as const;
export type StockSurfacesEnum = typeof StockSurfacesEnum[keyof typeof StockSurfacesEnum];

/**
 * @export
 */
export const StockDesignsEnum = {
    Wood: 'WOOD',
    Stone: 'STONE',
    Concrete: 'CONCRETE',
    Abstract: 'ABSTRACT',
    Universal: 'UNIVERSAL',
    Marble: 'MARBLE',
    Metallic: 'METALLIC'
} as const;
export type StockDesignsEnum = typeof StockDesignsEnum[keyof typeof StockDesignsEnum];

/**
 * @export
 */
export const StockColorsEnum = {
    Beige: 'BEIGE',
    Red: 'RED',
    Brown: 'BROWN',
    Green: 'GREEN',
    Yellow: 'YELLOW',
    Blue: 'BLUE',
    White: 'WHITE',
    Black: 'BLACK',
    Gray: 'GRAY',
    Other: 'OTHER'
} as const;
export type StockColorsEnum = typeof StockColorsEnum[keyof typeof StockColorsEnum];


/**
 * Check if a given object implements the Stock interface.
 */
export function instanceOfStock(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StockFromJSON(json: any): Stock {
    return StockFromJSONTyped(json, false);
}

export function StockFromJSONTyped(json: any, ignoreDiscriminator: boolean): Stock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ean': !exists(json, 'ean') ? undefined : json['ean'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'thickness': !exists(json, 'thickness') ? undefined : json['thickness'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'priceForCurrentUser': !exists(json, 'priceForCurrentUser') ? undefined : json['priceForCurrentUser'],
        'pricePerPackage': !exists(json, 'pricePerPackage') ? undefined : json['pricePerPackage'],
        'sellingRateVAT': !exists(json, 'sellingRateVAT') ? undefined : json['sellingRateVAT'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'amountPerPackage': !exists(json, 'amountPerPackage') ? undefined : json['amountPerPackage'],
        'amountPerPalette': !exists(json, 'amountPerPalette') ? undefined : json['amountPerPalette'],
        'packagesOnStock': !exists(json, 'packagesOnStock') ? undefined : json['packagesOnStock'],
        'atypicalTile': !exists(json, 'atypicalTile') ? undefined : json['atypicalTile'],
        'discontinuedDescription': !exists(json, 'discontinuedDescription') ? undefined : json['discontinuedDescription'],
        'discontinuedEanCode': !exists(json, 'discontinuedEanCode') ? undefined : json['discontinuedEanCode'],
        'notDiscontinuedEanCode': !exists(json, 'notDiscontinuedEanCode') ? undefined : json['notDiscontinuedEanCode'],
        'tileFaceImages': !exists(json, 'tileFaceImages') ? undefined : json['tileFaceImages'],
        'showcaseImages': !exists(json, 'showcaseImages') ? undefined : json['showcaseImages'],
        'technicalParameters': !exists(json, 'technicalParameters') ? undefined : json['technicalParameters'],
        'types': !exists(json, 'types') ? undefined : json['types'],
        'editions': !exists(json, 'editions') ? undefined : json['editions'],
        'surfaces': !exists(json, 'surfaces') ? undefined : json['surfaces'],
        'designs': !exists(json, 'designs') ? undefined : json['designs'],
        'colors': !exists(json, 'colors') ? undefined : json['colors'],
        'alternativeSizes': !exists(json, 'alternativeSizes') ? undefined : ((json['alternativeSizes'] as Array<any>).map(AlternativeSizeFromJSON)),
        'alternativeStockEanCodes': !exists(json, 'alternativeStockEanCodes') ? undefined : json['alternativeStockEanCodes'],
        'bathroomSeriesStockEanCodes': !exists(json, 'bathroomSeriesStockEanCodes') ? undefined : json['bathroomSeriesStockEanCodes'],
    };
}

export function StockToJSON(value?: Stock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ean': value.ean,
        'name': value.name,
        'size': value.size,
        'thickness': value.thickness,
        'price': value.price,
        'priceForCurrentUser': value.priceForCurrentUser,
        'pricePerPackage': value.pricePerPackage,
        'sellingRateVAT': value.sellingRateVAT,
        'unit': value.unit,
        'amountPerPackage': value.amountPerPackage,
        'amountPerPalette': value.amountPerPalette,
        'packagesOnStock': value.packagesOnStock,
        'atypicalTile': value.atypicalTile,
        'discontinuedDescription': value.discontinuedDescription,
        'discontinuedEanCode': value.discontinuedEanCode,
        'notDiscontinuedEanCode': value.notDiscontinuedEanCode,
        'tileFaceImages': value.tileFaceImages,
        'showcaseImages': value.showcaseImages,
        'technicalParameters': value.technicalParameters,
        'types': value.types,
        'editions': value.editions,
        'surfaces': value.surfaces,
        'designs': value.designs,
        'colors': value.colors,
        'alternativeSizes': value.alternativeSizes === undefined ? undefined : ((value.alternativeSizes as Array<any>).map(AlternativeSizeToJSON)),
        'alternativeStockEanCodes': value.alternativeStockEanCodes,
        'bathroomSeriesStockEanCodes': value.bathroomSeriesStockEanCodes,
    };
}

